export const configuration = {
  cedApiKey: "f0IbEQZ1JkixjtSu5D1Rfi1gqj3c6ISjpSB8NFfKRQFUuDqoXmPMeDNMUvfPdr7nG1MC1h33Ruw2X62zkyI8FjrXv6K2ROnVarzX",
  environment: "sandbox",
  version: "1.0.831",
  backend: {
    graphqlUrl: "https://sandbox.newslithq.com/graphql",
    graphiqlUrl: "https://sandbox.newslithq.com/_ah/graphql",
    externalLoginUrl: "https://sandbox.newslithq.com/auth/external",
    baseUrl: "https://sandbox.newslithq.com",
  },
  fullstory: {
    orgId: "Y5ZAQ",
  },
  honeybadger: {
    apiKey: "260ba5e7",
    environmentName: "staging",
  },
  intercom: {
    appId: "onfykn6c",
  },
  launchdarkly: {
    clientSideId: "5f68ae03f294fc0b5bfc5f62",
    sdkKey: "sdk-3b5c9356-67c9-407e-a796-d98f18a0387e",
  },
  newslitUikit: {
    registry: "https://registry.npmjs.org/",
  },
  reader: {
    hostingUrl: "https://newslit-sandbox-reader.web.app",
    url: "https://reader.sandbox.newslithq.com",
  },
  segment: {
    apiKey: "m6iGGfGW1vXOgGHFNBX870yphT9QKfnT",
  },
  stripe: {
    publishableKey: "pk_test_eHfnvJEdTpyRYMXHjLuKnCFb",
  },
  zapier: {
    embeddedWidgetUrl: "https://zapier.com/apps/embed/widget.js?services=newslit&limit=5&html_id=js-zapier-integration-embed",
  },
};