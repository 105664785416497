import { Intent, NonIdealState, Spinner, SpinnerSize } from '@blueprintjs/core';
import { StoryCardSkeleton } from '@newslit/uikit';
import React, { useEffect, useRef, useState } from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import BriefInProgress from '../../components/brief-in-progress/BriefInProgress';
import BriefNavBar from '../../components/brief-nav-bar/briefNavBar';
import BriefStoryCard from '../../components/brief-story-card/BriefStoryCard';
import DeleteBriefDialog from '../../components/delete-brief-dialog/DeleteBriefDialog';
import EditBriefPanel from '../../components/edit-brief-panel/EditBriefPanel';
import InactiveSubscription from '../../components/inactive-subscription/inactiveSubscription';
import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner';
import NewslitApp from '../../components/newslit-app/NewslitApp';
import Panel from '../../components/ui/panel';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import DashboardPageLayout from '../../layouts/dashboard/dashboardPageLayout';
import { requestBriefStoriesPage } from '../../redux/actions/brief-stories-actions';
import { requestBriefStoryTweets } from '../../redux/actions/brief-story-tweets-actions';
import { destroyBrief, hideBriefDestroyPrompt } from '../../redux/actions/briefs-actions';
import { locationToSidebarTool, SidebarTool } from '../../redux/actions/SidebarTool';
import { createRefreshToken } from '../../services/account';
import { configuration } from '../../services/configuration';
import { Brief } from '../../services/models/brief';
import { BriefStatus } from '../../services/models/brief-status';
import { Story } from '../../services/models/story';

type PageParams = {
  briefId: string;
};

const BriefPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();

  let { briefId } = useParams<PageParams>();
  const briefIdInt = Number(briefId);

  const account = useAppSelector(state => state.session.account);
  const subscription = useAppSelector(state => state.session.account.subscription);
  const briefs: Brief[] = useAppSelector(state =>
    (state.briefs.items || []).filter(brief => brief.status !== BriefStatus.Draft)
  );

  const brief = briefs.find(x => x.id === briefIdInt);
  const briefFound = !!brief;
  const briefStatus = brief ? brief.status : undefined;

  const briefDetailsHash = JSON.stringify([brief?.keywords, brief?.language]);

  const canAddMoreBriefs = useAppSelector(state => {
    return briefs.length < (state.session.account.product?.features.briefs || 0);
  });

  const hasNotLoadedYet = useAppSelector(state => state.briefs.isFetching === undefined);

  const isFetching = useAppSelector(state => hasNotLoadedYet || !!state.briefs.isFetching);

  const [tool, setTool] = useState(SidebarTool.NONE);
  const [openEditPanel, setOpenEditPanel] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [readerAppConf, setReaderAppConf] = useState<any>();

  const readerApp = useRef(0);

  useEffect(() => {
    setTool(locationToSidebarTool(location.pathname));
  }, [location, setTool]);

  useEffect(() => {
    setOpenEditPanel(tool !== SidebarTool.NONE);
  }, [tool, setOpenEditPanel]);

  useEffect(() => {
    if (
      briefFound &&
      briefStatus &&
      briefStatus !== BriefStatus.InProgress &&
      !!subscription.active
    ) {
      dispatch(requestBriefStoriesPage(briefIdInt, 0));
    }
  }, [briefFound, briefStatus, briefIdInt, dispatch, briefDetailsHash, subscription]);

  if (isFetching) {
    return <LoadingSpinner />;
  }

  if (!brief) {
    return <Redirect to={`/dashboard`} />;
  }

  if (brief.status === BriefStatus.InProgress) {
    return <BriefInProgress canAddMoreBriefs={canAddMoreBriefs} />;
  }

  const handleEditPanelClose = () => {
    const pathName = location.pathname;

    const newPathName = pathName
      .replace('edit', '')
      .replace('slack', '')
      .replace('schedule', '')
      .replace('recipients', '')
      .replace('integrations', '');

    history.push(newPathName);
  };

  const requestStoryTweetsHandler = (story: Story) => {
    if (!isFetching && !story.isFetchingTweets) {
      dispatch(requestBriefStoryTweets(brief.id, story.id));
    }
  };

  const sendMessageToReader = async (data: any) => {
    const newslitApp = readerApp.current;
    const refreshToken = await createRefreshToken();

    if (!newslitApp) {
      return;
    }

    const app = newslitApp as any;

    data.token = refreshToken;

    app.contentWindow.postMessage(data, '*');
  };

  const getContent = () => {
    if (!subscription.active) {
      return (
        <InactiveSubscription
          subscription={subscription}
          title="Thank you for trying Newslit!"
          description="You've reached the end of your free trial. To access your News Brief, daily digests and more please select one of our paid plans."
        />
      );
    }

    if (!!brief && (!brief.stories || brief.stories.length === 0)) {
      if (!brief.stories || !!brief.isFetching) {
        return (
          <div className="tw-flex tw-flex-col tw-h-full tw-justify-center tw-items-center">
            <Spinner intent={Intent.PRIMARY} size={SpinnerSize.STANDARD} />
            <h2 className="tw-text-xl tw-font-bold tw-pt-3 md:tw-text-2xl">Loading stories</h2>
            <p className="tw-text-base tw-px-6 tw-pt-3 tw-text-center">
              We are looking matching stories for your report.
            </p>
          </div>
        );
      }

      return (
        <div className="tw-flex tw-h-screen tw-items-center tw-justify-center">
          <NonIdealState
            icon="search"
            title="No recent stories"
            description="Sorry, no stories have been found matching your keywords at the moment. You can check again by refreshing at
        a later time."
          />
        </div>
      );
    }

    return (
      <div className="tw-block sm:tw-flex sm:tw-h-full sm:tw-overflow-y-auto">
        <ul className="tw-grid tw-grid-cols-1 tw-gap-3 md:tw-grid-cols-2 xl:tw-grid-cols-3 2xl:tw-grid-cols-4">
          {brief.stories?.map(story => (
            <li key={story.id} className="tw-col-span-1 tw-bg-white">
              <BriefStoryCard
                key={story.id}
                story={story}
                briefId={brief.id}
                titleClick={(storyId, storyUri) => {
                  setIsDrawerOpen(true);
                  setReaderAppConf({ storyId, storyUri });
                }}
                requestStoryTweets={s => requestStoryTweetsHandler(s)}
              />
            </li>
          ))}

          {/* Infinite Scroll Indicator */}
          {brief.hasMoreStories && (
            <VisibilitySensor
              partialVisibility
              onChange={visible => {
                if (visible) {
                  dispatch(requestBriefStoriesPage(brief.id, brief.lastLoadedStoryCursor || 0));
                }
              }}
            >
              <>
                <li className="tw-col-span-1 tw-bg-white">
                  <StoryCardSkeleton />
                </li>
                <li className="tw-col-span-1 tw-bg-white">
                  <StoryCardSkeleton />
                </li>
                <li className="tw-col-span-1 tw-bg-white">
                  <StoryCardSkeleton />
                </li>
              </>
            </VisibilitySensor>
          )}
        </ul>
      </div>
    );
  };

  return (
    <DashboardPageLayout title={brief.name}>
      {/* main area */}
      <main className="tw-flex tw-flex-1 tw-z-0 tw-overflow-y-auto tw-overflow-hidden tw-bg-white focus:tw-outline-none">
        {/* Primary section */}
        <section className="tw-flex-1 tw-h-full tw-relative tw-flex tw-flex-col">
          {/* Brief Navbar */}
          <div className="tw-flex-shrink-0 tw-z-20">
            <div className="tw-h-12 sm:tw-hidden"></div>
            <div className="tw-fixed tw-z-10 tw-top-0 tw-right-0 tw-w-full sm:tw-relative sm:tw-top-auto sm:tw-right-auto">
              <BriefNavBar brief={brief} />
            </div>
          </div>

          {getContent()}
        </section>
      </main>

      <EditBriefPanel
        account={account}
        brief={brief}
        tool={tool}
        panelOpen={openEditPanel}
        onClosePanel={handleEditPanelClose}
      />

      <Panel isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <NewslitApp
          load={() => sendMessageToReader(readerAppConf)}
          appRef={readerApp}
          title={'Newslit Reader'}
          uri={configuration.reader.url}
        />
      </Panel>

      <DeleteBriefDialog
        brief={brief}
        open={!!brief.showDestroyPrompt}
        onAccept={() => dispatch(destroyBrief(brief.id))}
        onCancel={() => dispatch(hideBriefDestroyPrompt(brief.id))}
      />
    </DashboardPageLayout>
  );
};

export default BriefPage;
