import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import AddBriefPanel from '../../components/add-brief-panel/AddBriefPanel';
import DesktopSidebar from '../../components/desktop-sidebar/desktopSidebar';
import EmailEnforcer from '../../components/email-enforcer/EmailEnforcer';
import ErrorReporter from '../../components/error-reporter/ErrorReporter';
import { IOSInstallModal } from '../../components/ios-install-modal/iosInstallModal';
import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner';
import { MobileSidebar } from '../../components/mobile-sidebar/mobileSidebar';
import NotificationHub from '../../components/notification-hub/NotificationHub';
import PaywallModal from '../../components/paywall-modal/paywallModal';
import TimezoneInferer from '../../components/timezone-inferer/timezoneInferer';
import ToastNotifications from '../../components/toast-notifications/ToastNotifications';
import { usePaywallContext } from '../../contexts/paywall';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useApplicationAlerts } from '../../hooks/useApplicationAlert';
import { useAppSelector } from '../../hooks/useAppSelector';
import { requestCurrentAccount } from '../../redux/actions/account-actions';
import { closeMenu, dismissNotifHub } from '../../redux/actions/sidebar-actions';
import DefaultPageLayout from '../defaultPageLayout';

interface DashboardLayoutProps {
  title?: string;
}

const DashboardPageLayout: FunctionComponent<DashboardLayoutProps> = ({ title, children }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const match = useRouteMatch();
  const { search } = useLocation();

  const alerts = useApplicationAlerts();
  const { paywallModalShown, setPaywallModalShown } = usePaywallContext();

  const isNotificationHubShown = useAppSelector(
    state => state.dashboardConfiguration?.isNotifHubOpen === true
  );
  const isMenuOpen = useAppSelector(state => state.dashboardConfiguration?.isMenuOpen === true);
  const [openAddNewBrief, setOpenAddNewBrief] = useState(false);
  const isLoadingSession = useAppSelector(state => state.session?.isFetching);
  const account = useAppSelector(state => state.session?.account);
  const briefs = useAppSelector(state => state.briefs);
  const maxBriefCount = useAppSelector(state => state.session.account?.product?.features.briefs);
  const [iOsInstallModalShown, setIOsInstallModalShown] = useState(false);

  const [currentBriefCount, setCurrentBriefCount] = useState(0);

  useEffect(() => {
    const briefQueryParam = new URLSearchParams(search).get('brief');
    setOpenAddNewBrief(!!briefQueryParam && briefQueryParam === 'new');
  }, [search]);

  useEffect(() => {
    // Close our navigation panel on path changes
    dispatch(closeMenu());
  }, [pathname, dispatch]);

  useEffect(() => {
    if (!isLoadingSession && !account.metadata) {
      dispatch(requestCurrentAccount());
    }
  }, [isLoadingSession, account, dispatch]);

  useEffect(() => {
    if (!briefs || !briefs.items) {
      return;
    }

    setCurrentBriefCount(briefs.items.length);
  }, [briefs]);

  if (!account.metadata || !!briefs.isFetching) {
    return <LoadingSpinner />;
  }

  const handleAddNewBriefClick = () => {
    history.push(`${match.url}?brief=new`);
  };

  const handleTwitterSignInSuccess = () => {
    // Dispatch getting up-to-date user data
    dispatch(requestCurrentAccount());

    // Then got to social page
    setTimeout(() => history.push('/dashboard/social'));
  };

  return (
    <DefaultPageLayout title={title}>
      <div className="tw-h-full tw-flex tw-bg-white">
        <MobileSidebar
          account={account}
          briefs={briefs?.items || []}
          currentBriefCount={currentBriefCount}
          maxBriefCount={maxBriefCount}
          sidebarOpen={isMenuOpen}
          onCloseSidebar={() => dispatch(closeMenu())}
          onAddNewBrief={handleAddNewBriefClick}
          onTwitterSignInSuccess={handleTwitterSignInSuccess}
          onIosAppInstall={() => setIOsInstallModalShown(true)}
        />

        {/* Static sidebar for desktop */}
        <div className="tw-hidden md:tw-flex md:tw-flex-shrink-0">
          <DesktopSidebar
            account={account}
            briefs={briefs?.items || []}
            currentBriefCount={currentBriefCount}
            maxBriefCount={maxBriefCount}
            onAddNewBrief={handleAddNewBriefClick}
            onTwitterSignInSuccess={handleTwitterSignInSuccess}
          />
        </div>

        {/* Main Area */}
        <div className="tw-min-w-0 tw-flex tw-flex-1 tw-overflow-hidden">
          {/* Children Component Area */}
          {children}
        </div>

        {openAddNewBrief && (
          <AddBriefPanel
            account={account}
            panelOpen={openAddNewBrief}
            onClosePanel={() => history.push(match.url)}
          />
        )}

        {iOsInstallModalShown && (
          <IOSInstallModal
            isOpen={iOsInstallModalShown}
            onClose={() => setIOsInstallModalShown(false)}
          />
        )}

        {/* Mobile Upsell notification */}
        {isNotificationHubShown && (
          <NotificationHub
            account={account}
            alerts={alerts}
            isVisible={isNotificationHubShown}
            onDismiss={() => dispatch(dismissNotifHub())}
          />
        )}

        {paywallModalShown && (
          <PaywallModal isOpen={paywallModalShown} onClose={() => setPaywallModalShown(false)} />
        )}

        <ToastNotifications />
        <ErrorReporter />
        <TimezoneInferer />
        <EmailEnforcer />
      </div>
    </DefaultPageLayout>
  );
};

export default DashboardPageLayout;
