import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App/App';
import ErrorPage from './components/error-page/errorPage';
import { store } from './redux/store';
import * as honeybadger from './services/honeybadger';
import * as plausible from './services/plausible';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './styles/index.css';

honeybadger.bootstrap();
plausible.bootstrap();

ReactDOM.render(
  <HoneybadgerErrorBoundary honeybadger={Honeybadger} ErrorComponent={ErrorPage}>
    <ReduxProvider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ReduxProvider>
  </HoneybadgerErrorBoundary>,
  document.getElementById('app')
);

// CRA Service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
