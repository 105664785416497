import { Transition } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { discardToast } from '../../redux/actions/toast-notifications-actions';
import './ToastNotifications.scss';

const ToastNotifications: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const toastNotification = useAppSelector(state => state.toastNotification);

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (toastNotification !== '') {
      setShow(true);
    }
  }, [toastNotification, setShow]);

  if (!toastNotification) {
    return <></>;
  }

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="tw-fixed tw-inset-0 tw-flex tw-items-end tw-px-4 tw-py-6 tw-pointer-events-none sm:tw-p-6 sm:tw-items-start"
      >
        <div className="tw-w-full tw-flex tw-flex-col tw-items-center tw-space-y-4 sm:tw-items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="tw-transform tw-ease-out tw-duration-300 tw-transition"
            enterFrom="tw-translate-y-2 tw-opacity-0 sm:tw-translate-y-0 sm:tw-translate-x-2"
            enterTo="tw-translate-y-0 tw-opacity-100 sm:tw-translate-x-0"
            leave="tw-transition tw-ease-in tw-duration-100"
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
          >
            <div className="tw-max-w-sm tw-w-full tw-bg-white tw-shadow-lg tw-rounded-lg tw-pointer-events-auto tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-overflow-hidden">
              <div className="tw-p-4">
                <div className="tw-flex tw-items-start">
                  <div className="tw-flex-shrink-0">
                    <CheckCircleIcon
                      className="tw-h-6 tw-w-6 tw-text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="tw-ml-3 tw-w-0 tw-flex-1 tw-pt-0.5">
                    <p className="tw-text-sm tw-font-medium tw-text-gray-900">
                      Successfully saved!
                    </p>
                    <p className="tw-mt-1 tw-text-sm tw-text-gray-500">{toastNotification}</p>
                  </div>
                  <div className="tw-ml-4 tw-flex-shrink-0 tw-flex">
                    <button
                      className="tw-bg-white tw-rounded-md tw-inline-flex tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500"
                      onClick={() => {
                        dispatch(discardToast());
                      }}
                    >
                      <span className="tw-sr-only">Close</span>
                      <XIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

export default ToastNotifications;
